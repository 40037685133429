<template>
  <step-wrapper title="Нежелательные события">
    <v-form :readonly="readonly" v-model="valid">
      <base-list-view
        v-if="dataSource.EventsContent"
        :dataSource="dataSource.EventsContent.Events"
        :headers="headers"
        hide-default-footer
        pageEditName="UnwishedEventEdit"
        :showSettings="false"
        :notShowActions="['add', 'refresh', 'delete']"
        notShowSelect
        @onRefresh="actual"
        group-by="Type"
        :item-class="
          (item) => {
            return !item.IncludeReport ? 'disabled' : '';
          }
        "
        not-filter
      >
        <template #group.header.after="{ items }">
          <span class="subtitle-2">
            (<span>{{ items.length }}</span
            >)
          </span>
        </template>
        <template slot="item.IncludeReport" slot-scope="scope">
          <v-checkbox v-model="scope.item.IncludeReport" @click.stop />
        </template>
      </base-list-view>

      <div class="mt-12">
        <div class="title">Заключение</div>
        <v-textarea
          v-if="dataSource.EventsContent"
          :value="dataSource.EventsContent.Conclusion"
          @change="dataSource.EventsContent.Conclusion = $event"
          placeholder="Заключение..."
        ></v-textarea>
      </div>
    </v-form>
  </step-wrapper>
</template>
<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import stepWrapper from "../stepWrapper.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";

import { defaultSummaryReport } from "@/data/defaultObjects";
import SummaryReportService from "@/services/SummaryReportService";
import BaseListView from "@/layouts/BaseListView.vue";
export default {
  name: "consolidated-report-step3",
  components: { stepWrapper, BaseListView },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  inject: ["setDefaultData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiService: SummaryReportService.step3,
      getDefaultObject: defaultSummaryReport.step3(),
      valid: false,
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },

      loading: false,
      dataSource: {},

      headers: [
        {
          text: "Вид нежелательного события",
          value: "Type",
          dataType: "object",
          displayText: (type) => type?.Name,
          width: "1%",
        },
        {
          text: "Нежелательное событие",
          value: "Content",
        },
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          width: "1%",
        },
        {
          text: "Последствия",
          value: "ConsequenceType",
          dataType: "object",
          displayText: (type) => type?.Description,
        },
        {
          text: "Результат ",
          value: "Result",
        },
        {
          text: "Включить в отчет",
          value: "IncludeReport",
          width: "1%",
        },
      ],
    };
  },
  computed: {},
  watch: {
    isUnsaved(val) {
      this.$emit("update:isUnsaved", val);
    },
    loading: {
      immediatle: true,
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.baseInit();
      this.setDefaultData(this.dataSource);
    },
    actual() {
      console.log("actual");
      this.init({
        query: {
          actual: true,
        },
      });
    },
  },
};
</script>
